import axios from 'axios';

export default {
  namespaced: true,

  state: {
    articles: [],
  },

  mutations: {
    getLatestNews(state) {
      axios.get(`${process.env.VUE_APP_BACKEND_URL}/api/news`).then((res) => {
        state.articles = res.data;
      });
    }
  },

  actions: {
    init({
      commit
    }) {
      commit('getLatestNews');
    },
  },

  getters: {
    articles: state => state.articles,
  },
};
