<script>
import { mapActions, mapState, mapGetters } from 'vuex';

export default {
  name: 'checkout',

  data() {
    return {
      stepMap: [
        { name: 'checkoutSummary', label: 'summary' },
        { name: 'checkoutCustomerType', label: 'type' },
        { name: 'checkoutInformation', label: 'info' },
      ],
    };
  },

  created() {
    this.init();
  },

  computed: {
    ...mapState('checkout', ['isLoading', 'customerType']),
    ...mapState('cart', ['items']),
  },

  methods: {
    ...mapActions('checkout', ['init']),

    isActive(step) {
      const currentStep = this.$route.name;
      return step === this.stepMap.find(s => s.name === currentStep).label;
    },

    getStepClass(step) {
      return this.isActive(step) ? 'text-xl text-black' : 'text-gray-600';
    },
  },
};
</script>

<template>
  <div class="container flex flex-col-reverse justify-center lg:flex-row">
    <template v-if="items.length > 0">
      <div class="flex-1 px-8">
        <template v-if="isLoading">{{ $t("loading") }}...</template>

        <template v-else>
          <router-view></router-view>
        </template>
      </div>
    </template>

    <template v-else>
      <div class="w-full py-8">
        <p class="inline-block text-lg">
          {{ $t('misc.cart.empty') }}
        </p>
      </div>
    </template>
  </div>
</template>
