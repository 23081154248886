import axios from 'axios';
import orderService from '@/services/order';

const actions = {
  init: async ({ commit, dispatch }) => {
    commit('SET_LOADING', true);

    const localPurchase = window.localStorage.getItem('purchase');
    if (localPurchase) {
      await dispatch('resume', localPurchase);
    }

    const localCustomerType = window.localStorage.getItem('customer');
    if (localCustomerType) {
      const customerType = JSON.parse(localCustomerType);
      commit('SET_CUSTOMER_TYPE', customerType);
    }

    axios
      .get(`${process.env.VUE_APP_BACKEND_URL}/api/vat`)
      .then((res) =>
        res.data.sort((a, b) => (a.name_sv < b.name_sv ? -1 : a.name_sv > b.name_sv ? 1 : 0)),
      )
      .then((list) => {
        commit('SET_COUNTRY_LIST', list);
        commit('SET_LOADING', false);
      });
  },

  reset: async ({ state }) => {
    state.purchase = null;
    state.customer = {
      type: 'private',
      country: null,
      delivery: 'digital',
    };
    state.vat = null;
  },

  setCustomer: async ({ state, commit }, data) => {
    commit('SET_CUSTOMER', data);

    const updatedCustomer = { ...state.customerType, ...data };
    window.localStorage.setItem('customer', JSON.stringify(updatedCustomer));
  },

  setCustomerValidated: async ({ state }) => {
    state.customerValidated = true;
  },

  setVat: async ({ state, commit }) => {
    const vat = await orderService.getVat(
      state.customer.type,
      state.customer.country.code,
      state.customer.orgnr,
    );

    commit('SET_VAT', vat);
    return vat;
  },

  setSubscribed: async ({ state }) => {
    state.subscribed = true;
  },

  setSubscribedFalse: async ({ state }) => {
    state.subscribed = false;
  },
  setDelivery: async ({ state, commit }) => {
    const delivery = await orderService.getDelivery(
      state.customer.type,
      state.customer.country.code,
    );

    commit('SET_DELIVERY', delivery);
    return delivery;
  },

  createOrder: async ({ rootState, state }) => orderService.create(
    rootState.cart.items.map((i) => i.id),
    state.customer,
    rootState.currency,
    state.subscribed,
  ),
};

export default actions;
