const getters = {
  sumTotal: (state, _, rootState) => state.items.reduce((acc, i) => {
    // eslint-disable-next-line no-param-reassign
    acc += i.price[rootState.currency];
    return acc;
  }, 0),

  sumTotalExTax: (state, _, rootState) => state.items.reduce((acc, i) => {
    // eslint-disable-next-line no-param-reassign
    acc += (i.price[rootState.currency] * 0.75);
    return acc;
  }, 0),
};

export default getters;
