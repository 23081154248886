<script>
import { mapActions } from 'vuex';
import { resolveImageUrl } from '@/imageResolver.js'

export default {
  props: {
    value: {
      type: Object,
      required: true,
    },

    canDelete: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      image: this.value,
    };
  },

  methods: {
    ...mapActions('cart', ['remove']),

    resolveImageUrl,
  },
};
</script>

<template>
  <div class="bg-white rounded-lg h-20 w-full flex mb-6">
    <div
      class="p-2 h-full flex-none text-center overflow-hidden w-24"
      :title="image.headline"
    >
    <img class="object-cover h-full m-auto" :src="resolveImageUrl(image.ext_id, 'mini')" />
    </div>

    <div class="flex flex-col flex-1 justify-between p-4 text-left">
      <div class="mb-2">
        <div class="text-gray-900 font-bold text-sm">
          {{ image.headline }}
        </div>
        <div class="text-gray-900 text-xs">
          Bildnummer: {{ image.ext_id }}
        </div>
      </div>
    </div>

    <div class="flex flex-col justify-between py-4">
      <div class="text-sm self-center">
        <p class="text-gray-900 font-bold text-mb leading-none">
          {{ image.price | amount }}
        </p>
      </div>

      <button
        v-if="canDelete"
        class="h-6 w-6 md:h-8 md:w-8 self-end rounded-md text-white justify-center bg-red-500 hover:bg-red-700 text-xs"
        @click="remove(image.ext_id)"
      >
        <i class="fas fa-trash"></i>
      </button>
    </div>
  </div>
</template>
