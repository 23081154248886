import Vue from 'vue';
import Vuex from 'vuex';
import cart from './stores/cart';
import news from './stores/news';
import misc from './stores/misc';
import checkout from '@/views/checkout/store';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    misc,
    cart,
    news,
    checkout,
  },

  state: {
    currency: 'sek',
    isLoading: false,
  },

  mutations: {
    setLoadingState(state, isLoading) {
      state.isLoading = isLoading;
    },

    SET_CURRENCY(state, currency) {
      state.currency = currency;
    },
  },

  getters: {
    isLoading: state => state.isLoading,
  },
});
