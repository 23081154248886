<!-- Cookies.vue -->
<template>
    <div v-if="!isHidden" class="cookieWrapper">
        <!-- <div class="closeIcon" @click="hideCookies"> <img src="@/assets/closeIcon.png" ></div> -->
       <p> {{ $t("cookiesText") }}</p>
       <div class="cookiesButtonWrapper">
        <button class="p-2 rounded bg-blue-500 hover:bg-blue-700 text-white" @click="hideCookies">{{ $t("allowAllCookies") }}</button>
         <button class="p-2 rounded bg-blue-500 hover:bg-blue-700 text-white" @click="hideCookies">{{ $t("allowNecessary") }}</button>
       </div>
    </div>

</template>

<script>
export default {
    name: 'Cookies',
    data() {
        return {
            isHidden: false,
        };
    },
    created() {
        this.checkCookiesStatus();
    },
    methods: {
        hideCookies() {
            // Save to local storage
            localStorage.setItem('cookiesAccepted', 'true');
            // Update local state to hide the component
            this.isHidden = true;
        },
        checkCookiesStatus() {
            // Check local storage for cookies acceptance
            const cookiesAccepted = localStorage.getItem('cookiesAccepted');
            this.isHidden = cookiesAccepted === 'true';
        },
    },
};
</script>

<style lang="scss">
 .cookieWrapper{
    background: #fff;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.75);
     padding: 20px;

    .cookiesButtonWrapper{
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;

        @media only screen and (max-width: 600px) {
             flex-direction: column;
        }
    }

    .closeIcon{
        position: absolute;
        font-size: 30px;
        cursor: pointer;
        right: 15px;
        top: 15px;

            img{
                width: 20px;
                height: 20px;
            }
    }
 }
</style>
