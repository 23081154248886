import { readFromLocalStorage } from './utils';

const actions = {
  init: async ({ commit }) => {
    const items = readFromLocalStorage();
    items.forEach((item) => commit('ADD', item));
  },

  add: async ({ rootState, commit }, image) => {
    if (rootState.cart.items.find((i) => i.id === image.id)) {
      return;
    }

    commit('ADD', image);
  },

  remove: async ({ commit }, id) => {
    commit('REMOVE', id);
  },

  empty: async ({ commit }) => {
    commit('EMPTY');
  },
};

export default actions;
