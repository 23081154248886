import axios from 'axios';

export default {
  namespaced: true,

  state: {
    categories: [],
  },

  mutations: {
    getCategories(state) {
      axios.get(`${process.env.VUE_APP_BACKEND_URL}/api/category`).then((res) => {
        state.categories = res.data;
      });
    },
  },

  actions: {
    init({
      commit,
    }) {
      commit('getCategories');
    },
  },

  getters: {
    rootCategories: state => state.categories,
  },
};
