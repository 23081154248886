import {
  syncToLocalStorage,
} from './utils';

const mutations = {
  SET_LOADING: (state, isLoading) => {
    state.isLoading = isLoading;
  },

  ADD: (state, image) => {
    state.items.push(image);
    syncToLocalStorage(state.items);
  },

  REMOVE: (state, imageId) => {
    const ref = state.items.find(i => i.id === imageId);
    const i = state.items.indexOf(ref);
    state.items.splice(i, 1);

    syncToLocalStorage(state.items);
  },

  EMPTY: (state) => {
    state.items = [];
  },
};

export default mutations;
