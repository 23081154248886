<script>
export default {

};
</script>

<template>
  <div class="w-full flex p-4 text-black align-center justify-center" style="background: #DDEAF0">
    <div>
      © {{ $t("imageArchive") }} 2024
    </div>
  </div>
</template>
