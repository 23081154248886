<script>
export default {
  name: 'search-bar',

  props: {
    showLatest: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      query: this.value,
    };
  },

  beforeRouteLeave(to, from, next) {
    console.log("changed");
    if (this.to) {
      next();
    } else {
      this.to = to;
      console.log("changed");
    }
  },

  mounted() {
    const initQuery = this.$router.currentRoute.query.q;

    if (initQuery) {
      this.query = initQuery;
    }
  },

  watch:{
    '$route' (to, from){
      const initQuery = to.query.q;

      if (initQuery) {
        this.query = initQuery;
      } else {
        this.query = "";
      }
    }
  },

  methods: {
    search() {
      if (this.query.length === 0) {
        return;
      }

      this.$router.push({ name: 'library-search', query: { q: this.query } }).catch(() => {});
    },
  },
};
</script>

<template>
  <div class="p-2 relative mx-auto text-gray-600 w-full">
    <input
      class="bg-white text-xl appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
      type="search"
      name="search"
      v-model="query"
      @keyup.13="search()"
      :placeholder="$t('search')"
    />
    <button type="submit" class="h-full absolute right-0 top-0 flex p-6 items-center">
      <i class="fas fa-search text-2xl" @click="search()"></i>
    </button>
  </div>
</template>
