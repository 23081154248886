export const syncToLocalStorage = (cart) => {
  window.localStorage.setItem('cart', JSON.stringify({
    items: cart,
  }));
};

export const readFromLocalStorage = () => {
  const storeData = window.localStorage.getItem('cart');

  if (!storeData) {
    return [];
  }

  return JSON.parse(storeData).items;
};
