<script>
export default {
  name: 'search-bar',

  props: {
    showLatest: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      query: this.value,
    };
  },

  mounted() {
    const initQuery = this.$router.currentRoute.query.q;

    if (initQuery) {
      this.query = initQuery;
    }
  },

  methods: {
    search() {
      if (this.query.length === 0) {
        return;
      }

      this.$router.push({ name: 'library-search', query: { q: this.query } }).catch(() => {});
    },

    updateLocalStore(query) {
      const current = JSON.parse(localStorage.getItem('searches')) || [];
      current.unshift(query);
      current.slice(2);

      localStorage.setItem('searches', JSON.stringify(current));
    },
  },
};
</script>

<template>
  <div class="p-2 relative mx-auto text-gray-600 w-full">
    <button type="submit" class="h-full flex items-center">
      <i class="fas fa-search" @click="search()"></i>
    </button>
  </div>
</template>
