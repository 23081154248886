<script>
import { mapState, mapActions } from 'vuex';
import CartItemContainer from '@/components/CartItemContainer.vue';

export default {
  computed: {
    ...mapState(['currency']),
    ...mapState('cart', ['items']),

    sumTotal() {
      return (
        this.items.reduce((acc, i) => {
          // eslint-disable-next-line no-param-reassign
          acc += i.price[this.currency];
          return acc;
        }, 0)
      );
    },

    sumTotalExTax() {
      return this.items.reduce((acc, i) => {
        // eslint-disable-next-line no-param-reassign
        acc += i.price[this.currency];
        return acc;
      }, 0);
    },
  },

  components: {
    CartItemContainer,
  },

  methods: {
    ...mapActions('cart', ['remove']),

    close() {
      this.$emit('onClose');
    },
  },
};
</script>

<template>
  <div class="dropdown z-50 shadow-lg p-4 flex-col">
    <div class="flex justify-between w-full text-lg py-4">
      <p class="text-2xl">{{ $t("checkout.yourCart") }}</p>

      <button @click="close" class="h-8 w-8 rounded bg-blue-500 hover:bg-blue-700 text-white">
        <i class="fas fa-times"></i>
      </button>
    </div>

    <template v-if="items.length > 0">
      <div>
        <cart-item-container
          v-for="(image, i) in items"
          :key="i"
          v-model="items[i]"
        />
      </div>

      <div class="my-4 flex justify-between">
        <p class="text-2xl">Total:</p>
        <p class="text-lg">{{ sumTotal }} {{ currency.toUpperCase() }}</p>
      </div>

      <div class="w-full">
        <localized-link
          :to="{ name: 'cartSummary' }"
          tag="button"
          @click.native="close"
          class="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            {{ $t('checkout.button.goto') }}
          </localized-link
        >
      </div>
    </template>

    <template v-else>
      {{ $t('misc.cart.empty') }}
    </template>
  </div>
</template>

<style lang="scss" scoped>
.dropdown {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #fff;
  left: 0;
  top: 0;

  @media (min-width: 640px) {
    width: 600px;
    top: 3rem;
    right: 2rem;
    left: initial;
    height: auto;
  }
}
</style>
