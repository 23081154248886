import axios from 'axios';
import checkout from "../views/checkout/Checkout.vue";

const api = axios.create({
  baseURL: `${process.env.VUE_APP_BACKEND_URL}/api`,
});

const formatCustomer = (customer) => {
  const formatedCustomer = { ...customer };

  formatedCustomer.country = customer.country.code;

  return formatedCustomer;
};

const payment = {
  create: async (items, customer, currency, subscribed) => {
    const res = await api.post('/order', {
      items,
      customer,
      currency,
      subscribed,
    });
    return res.data;
  },

  payManually: async (ref) => {
    const res = await api.post(`/order/${ref}/payment`, {
      type: 'manual',
    });
    return res.data;
  },

  findOrCreateIntent: async (ref) => {
    const res = await api.get(`/order/${ref}/payment/intent`);
    return res.data;
  },

  receipt: async (ref) => {
    const res = await api.get(`/order/${ref}/receipt`);
    return res.data;
  },

  getDelivery: async (type, country) => {
    const res = await api.get(`/payment/delivery/${type}/${country}`);
    return res.data;
  },

  getVat: async (type, country, vatNr = '') => {
    const res = await api.get(`/payment/vat/${type}/${country}?vatNumber=${vatNr}`);
    return res.data;
  },
};

export default payment;
