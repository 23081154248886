import Vue from 'vue';
import { i18n } from 'vue-lang-router';
import App from './App.vue';
import router from './router';
import store from './store';
import VueMeta from 'vue-meta';

import '@/assets/css/tailwind.css';

Vue.config.productionTip = false;

Vue.use(VueMeta);

// Define a filter to format amounts based on selected currency
Vue.filter('amount', (val) => {
  if (store.state.currency && store.state.currency in val) {
    const amount = val[store.state.currency];
    const currency = store.state.currency.toUpperCase();
    return `${amount} ${currency}`;
  }
  // Default return if currency is not found
  return val;
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
