import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
  isLoading: false,
  purchase: null,
  vat: null,
  customer: {
    type: 'private',
    country: null,
    delivery: 'digital',
  },
  customerValidated: false,
  subscribed: false,
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
