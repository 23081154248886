const getters = {
  intent: (state) => state.purchase.intent,

  customer: (state) => state.customer,
  subscribed: (state) => state.subscribed,

  isLoading: (state) => state.isLoading,

  countryList: (state) => state.countryList,
};

export default getters;
