const mutations = {
  SET_LOADING: (state, isLoading) => {
    state.isLoading = isLoading;
  },

  SET_ITEMS: (state, items) => {
    state.purchase.items = items;
  },

  SET_CUSTOMER: (state, customer) => {
    Object.assign(state.customer, customer);
  },

  SET_CUSTOMER_TYPE: (state, info) => {
    Object.assign(state.customer, info);
  },

  SET_COUNTRY_LIST: (state, list) => {
    state.countryList = list;
  },

  SET_VAT: (state, vat) => {
    state.vat = vat;
  },

  SET_DELIVERY: (state, delivery) => {
    state.delivery = delivery;
  },

  SET_SUBSCRIBED: (state, subscribed) => {
    state.subscribed = subscribed;
  },
};

export default mutations;
